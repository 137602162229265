<template>
  <div></div>
</template>

<script>
import { AFFILIATE_REFERRAL_CODE } from '@/store/affiliate.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
import { uiHelper, locale } from '@/util'
export default {
  name: 'affiliateReferral',
  data: () => ({
    affiliateLandingPage: '',
    affiliateReferralCode: '',
    affiliateReferralName: '',
    language: ''
  }),
  created() {
    this.metaTag()
    this.initializeAffiliateReferral()
  },
  computed: {
    referalCode() {
      return this.$store.state.affiliate.referralCode
    }
  },
  watch: {
    referalCode() {
      this.affiliateReferralCode = this.$store.state.affiliate.referralCode
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      const Linksenin = `https://${domain}/en-IN${cleanPath}`;
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`;
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`;
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`;

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    changeLanguage(language) {
      locale.setCurrentLanguage(language)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
    },
    redirectToPage(page) {
      switch (page) {
        case 'register':
          this.$router.push({
            name: ROUTE_NAME.HOME,
            params: {
              action: SHARED.REGISTER
            }
          })
          break
        case 'rng':
          this.$router.push({
            name: ROUTE_NAME.SLOT
          })
          break
        case 'promotion':
          this.$router.push({
            name: ROUTE_NAME.PROMOTION
          })
          break
        default:
          this.$router.push({
            name: ROUTE_NAME.HOME
          })
          break
      }
    },
    async getReferralCode(name) {
      let obj = {
        name: name
      }
      await this.$store.dispatch(`${AFFILIATE_REFERRAL_CODE}`, { obj })
    },
    async initializeAffiliateReferral() {
      this.affiliateLandingPage = this.$route.query.aff_page
      this.affiliateReferralCode = this.$route.query.aff_code
      this.affiliateReferralName = this.$route.query.aff_name
      this.language = this.$route.query.language

      /* SET LANGUAGE */
      locale.setCurrentLanguage(this.language)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', this.language)

      /* GET AFFILIATE REFERRAL CODE */
      if (this.affiliateReferralCode == undefined && this.affiliateReferralName != '') {
        await this.getReferralCode(this.affiliateReferralName)
      }

      //uiHelper.removeCookie('affReferralCode')
      uiHelper.setLocalStorage('affReferralCode', this.affiliateReferralCode, 100)

      this.redirectToPage(this.affiliateLandingPage)
    }
  }
}
</script>
